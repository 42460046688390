
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import LoansTable from "@/components/loans/LoansTable.vue";
import LoanModal from "@/components/loans/LoanModal.vue";
import LoanApproveModal from "@/components/loans/LoanApproveModal.vue";
import LoanRefuseModal from "@/components/loans/LoanRefuseModal.vue";
import LoanCancelModal from "@/components/loans/LoanCancelModal.vue";
import LoanUpdateStatusModal from "@/components/loans/LoanUpdateStatusModal.vue";
import LoanUpdateCompanyModal from "@/components/loans/LoanUpdateCompanyModal.vue";
import LoanUndoFiredEmployeeModal from "@/components/loans/LoanUndoFiredEmployeeModal.vue";
import LoanService from "@/services/loan-service";
import ListLoansParams from "@/services/loan-service/types/list-loans-params";
import Loan from "@/types/loan";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanFlowModal from "@/components/loans/LoanFlowModal.vue";
import UpdateRefusedBankSlipModal from "@/components/loans/UpdateRefusedBankSlipModal.vue";

@Component({
  components: {
    Page,
    LoansTable,
    LoanModal,
    LoanApproveModal,
    LoanRefuseModal,
    LoanCancelModal,
    LoanUndoFiredEmployeeModal,
    LoanUpdateStatusModal,
    LoanFlowModal,
    LoanUpdateCompanyModal,
    UpdateRefusedBankSlipModal
  }
})
export default class Loans extends Vue {
  service: LoanService;
  loans: { items: Array<Loan>; total: number };
  loan: Loan | null;
  refreshLoans: number = 0;
  showLoanApproveModal: boolean;
  showLoanRefuseModal: boolean;
  showLoanCancelModal: boolean;
  showLoanUndoFiredEmployeeModal: boolean;
  showLoanModal: boolean;
  showLoanUpdateStatusModal: boolean;
  showCreateLoanModal: boolean;
  showLoanUpdateCompanyModal: boolean;
  showUpdateRefusedBankSlipModal: boolean;

  defaultReturn = { items: [], total: 0 };

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.loans = { ...this.defaultReturn };
    this.loan = null;
    this.showLoanApproveModal = false;
    this.showLoanRefuseModal = false;
    this.showLoanCancelModal = false;
    this.showLoanUndoFiredEmployeeModal = false;
    this.showLoanModal = false;
    this.showLoanUpdateStatusModal = false;
    this.showCreateLoanModal = false;
    this.showLoanUpdateCompanyModal = false;
    this.showUpdateRefusedBankSlipModal = false;
  }

  showLoan(item: Loan): void {
    this.showLoanModal = true;
    this.loan = { ...item };
  }

  approveModal(item: Loan): void {
    this.showLoanApproveModal = true;
    this.loan = { ...item };
  }

  refuseModal(item: Loan): void {
    this.showLoanRefuseModal = true;
    this.loan = { ...item };
  }

  cancelModal(item: Loan) {
    this.showLoanCancelModal = true;
    this.loan = { ...item };
  }

  updateStatusModal(item: Loan) {
    this.showLoanUpdateStatusModal = true;
    this.loan = { ...item };
  }

  updateCompanyModal(item: Loan) {
    this.showLoanUpdateCompanyModal = true;
    this.loan = { ...item };
  }

  undoFiredEmployeeModal(item: Loan): void {
    this.showLoanUndoFiredEmployeeModal = true;
    this.loan = { ...item };
  }

  updateRefusedBankSlipModal(item: Loan): void {
    this.showUpdateRefusedBankSlipModal = true;
    this.loan = { ...item };
  }

  async approveLoan(item: Loan) {
    const [err] = await this.service.approveLoan({
      loanId: item.id
    });
    this.handleRefreshLoans();
    if (!err) {
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Empréstimo aprovado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
    this.closeLoanApproveModal();
  }

  async refuseLoan(item: {
    loan: Loan;
    nonEndorsementMotiveId: number;
    endorsementObservation: string;
    nonEndorsementAvailableMargin: number;
  }): Promise<void> {
    const [err] = await this.service.refuseLoan({
      loanId: item.loan.id,
      nonEndorsementMotiveId: item.nonEndorsementMotiveId,
      endorsementObservation: item.endorsementObservation,
      nonEndorsementAvailableMargin: item.nonEndorsementAvailableMargin
    });
    this.handleRefreshLoans();
    if (!err) {
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Empréstimo recusado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
    this.closeLoanRefuseModal();
  }

  async cancelLoan(item: Loan): Promise<void> {
    const [err] = await this.service.cancelLoan({
      loanId: item.id,
      cancelationMotive: item.cancelationMotive!
    });
    this.handleRefreshLoans();
    if (!err) {
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Empréstimo cancelado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
    this.closeLoanCancelModal();
  }

  async updateLoanStatus(item: {
    id: number;
    status: string;
    observation: string;
    paidDate: string;
  }): Promise<void> {
    const [err] = await this.service.updateLoanStatus(item.id, {
      status: item.status,
      observation: item.observation,
      paidDate: item.paidDate
    });

    this.closeLoanUpdateStatusModal();
    if (!err) {
      this.handleRefreshLoans();
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Status alterado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
  }

  async updateLoanCompany(item: {
    id: number;
    companyId: string;
  }): Promise<void> {
    const [err] = await this.service.updateLoanCompany(item.id, {
      companyId: item.companyId
    });

    if (!err) {
      this.handleRefreshLoans();
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Empréstimo transferido com sucesso."
      });
      this.closeLoanUpdateCompanyModal();
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
  }

  async undoFiredEmployeeLoan(item: Loan): Promise<void> {
    const [err] = await this.service.undoFiredEmployeeLoan({
      loanId: item.id
    });
    this.handleRefreshLoans();
    if (!err) {
      this.$notify({
        title: "Empréstimo",
        type: "success",
        text: "Funcionário religado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
    this.closeLoanUndoFiredEmployeeModal();
  }

  handleRefreshLoans(): void {
    this.refreshLoans++;
  }

  closeLoan(): void {
    this.loan = null;
    this.showLoanModal = false;
  }
  closeLoanApproveModal(): void {
    this.loan = null;
    this.showLoanApproveModal = false;
  }
  closeLoanRefuseModal(): void {
    this.loan = null;
    this.showLoanRefuseModal = false;
  }
  closeLoanCancelModal(): void {
    this.loan = null;
    this.showLoanCancelModal = false;
  }
  closeLoanUpdateStatusModal(): void {
    this.loan = null;
    this.showLoanUpdateStatusModal = false;
  }
  closeLoanUpdateCompanyModal(): void {
    this.loan = null;
    this.showLoanUpdateCompanyModal = false;
  }
  closeLoanUndoFiredEmployeeModal(): void {
    this.loan = null;
    this.showLoanUndoFiredEmployeeModal = false;
  }

  closeUpdateRefusedBankSlipModal(): void {
    this.loan = null;
    this.showUpdateRefusedBankSlipModal = false;
  }

  async fetchLoans(options: ListLoansParams): Promise<void> {
    const {
      loanId,
      page,
      limit,
      sort,
      borrowerCpf,
      proposalNumber,
      companyId,
      statusId,
      simulationTypeId,
      statusGroupId,
      financialInstitutionId,
      typeId,
      startedSimulationDateStart,
      startedSimulationDateEnd,
      requestDateStart,
      requestDateEnd,
      updatedAtDateStart,
      updatedAtDateEnd,
      paidDateStart,
      paidDateEnd
    } = options;

    let [err, loanData] = await this.service.listLoans({
      loanId,
      page,
      limit,
      sort,
      borrowerCpf,
      proposalNumber,
      companyId,
      statusId,
      simulationTypeId,
      statusGroupId,
      financialInstitutionId,
      typeId,
      startedSimulationDateStart,
      startedSimulationDateEnd,
      requestDateStart,
      requestDateEnd,
      updatedAtDateStart,
      updatedAtDateEnd,
      paidDateStart,
      paidDateEnd
    });

    if (!err) {
      this.loans = loanData!;
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empréstimo", type: "error", text: message });
    }
  }

  createLoan(): void {
    this.showCreateLoanModal = true;
  }

  closeCreateLoan(): void {
    this.showCreateLoanModal = false;
  }

  loanCreated(): void {
    this.closeCreateLoan();
    this.handleRefreshLoans();
  }
}
