import HttpClient, { Response } from "./http-client";

export default class SafeBoardingService extends HttpClient {
  private static classInstance?: SafeBoardingService;

  private constructor() {
    super(process.env.VUE_APP_SAFE_BOARDING_URL!);
  }

  public static getInstance(): SafeBoardingService {
    if (!this.classInstance) {
      this.classInstance = new SafeBoardingService();
    }

    return this.classInstance;
  }

  public async runCreditEngine(
    data: RunCreditEngineData
  ): Promise<Response<RunCreditEngineResponse>> {
    return this.axiosCall<RunCreditEngineResponse>({
      method: "POST",
      url: `/safe/credit-engine`,
      data
    });
  }
}

interface RunCreditEngineData {
  loanType: string;
  withInsurance?: boolean;
  amount?: number;
  marginBaseRegisterId: number;
  unconsideredMarginInstallmentIds?: number[];
  numInstallments?: number;
}

export interface RunCreditEngineResponse {
  document?: string;
  minAmount: number;
  maxAmount: number;
  minInstallments: number;
  maxInstallments: number;
  creditPolicyRanges: CreditPolicyRange[]
  fee: number;
  withRestriction: boolean;
}

export interface CreditPolicyRange {
  fee: number;
  amount: number;
  installmentMinimumRange: number;
  installmentMaximumRange: number;
}

