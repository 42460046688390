
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import LoanService from "@/services/loan-service";
import { mask } from "vue-the-mask";
import SaveButton from "@/components/SaveButton.vue";
import { SaveLoanResponse } from "@/services/loan-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import UploadFile from "@/components/UploadFile.vue";
import AlertBox from "@/components/AlertBox.vue";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import formatCurrency from "@/utils/formatCurrency";
import dayjs from "dayjs";
import banks from "@/data/bancos";
import accountTypes from "@/data/tipos-de-conta";
import Loan, { loanTypesIds, loanTypesNames } from "@/types/loan";
import { sumBy } from "lodash";
import { LoanPaymentTypeEnum } from "@/types/LoanPaymentTypeEnum";
import CompanyService from "../../services/company-service";
import { getBankingEnumNameById } from "../../types/BankingEnum";
import { getAdministratorEnumNameById } from "../../types/AdministratorEnum";

@Component({
  components: { ValidationObserver, Modal, SaveButton, UploadFile, AlertBox },
  directives: { mask }
})
export default class LoanResume extends Vue {
  formatCPF = formatCPF;
  formatCNPJ = formatCNPJ;
  formatCurrency = formatCurrency;
  @Prop() readonly loan!: SaveLoanResponse;
  loadingResume: boolean = false;
  loadingBankingAndAdministrator: boolean = false;
  loanService: LoanService;
  companyService: CompanyService;
  resume = {} as Loan & {
    firstInstallmentDueDate: string;
  };
  banks = banks;
  accountTypes = accountTypes;
  productNoLongerAvailable: boolean = false;
  success: boolean = false;
  loanTypesIds = loanTypesIds;
  loanTypesNames = loanTypesNames;
  LoanPaymentTypeEnum = LoanPaymentTypeEnum;

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
  }

  created(): void {
    this.loadResume();
    this.syncProductData();
  }

  get loading(): boolean {
    return this.loadingResume || this.loadingBankingAndAdministrator;
  }

  public async loadResume(): Promise<void> {
    this.loadingResume = true;
    const [error, response] = await this.loanService.getLoanResume(
      this.loan.borrower.cpf
    );
    this.loadingResume = false;

    if (error || !response) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });

      return;
    }

    this.resume = response;
  }

  async syncProductData(): Promise<void> {
    this.loadingBankingAndAdministrator = true;

    const companyId = this.loan.loan.companyId;
    const typeId = this.loan.loan.typeId;

    const [productError, productData] =
      await this.companyService.findProductsByCompanies([companyId]);

    if (productError) {
      this.loadingBankingAndAdministrator = false;
      return this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(productError)
      });
    }

    if (productData) {
      const matchingProduct = productData[companyId].find(
        (product) => product.typeId === typeId && product.isActive
      );

      if (!matchingProduct) {
        this.loadingBankingAndAdministrator = false;
        this.productNoLongerAvailable = true;
        return;
      }

      const [loanError, updatedLoan] = await this.loanService.saveLoanResume({
        cpf: this.loan.borrower.cpf,
        data: {
          loan: {
            bankingId: matchingProduct.bankingId,
            bankingName: getBankingEnumNameById(matchingProduct.bankingId),
            administratorId: matchingProduct.adminId,
            administratorName: getAdministratorEnumNameById(
              matchingProduct.adminId
            )
          }
        }
      });

      if (loanError) {
        this.loadingBankingAndAdministrator = false;
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(productError)
        });
      }
    }
    this.loadingBankingAndAdministrator = false;
  }

  async confirmLoan(): Promise<void> {
    this.loadingResume = true;
    try {
      const [error, response] = await this.loanService.finishLoan(
        this.loan.borrower.cpf
      );
      if (error) {
        this.$notify({
          title: "Erro",
          text: getErrorMessageFromApiError(error),
          type: "error"
        });
        return;
      }

      this.$notify({
        title: "Sucesso",
        text: "Empréstimo solicitado com sucesso.",
        type: "success"
      });
      this.success = true;
      this.$emit("disableNavigation");
      this.$emit("refresh");
    } catch (error) {
      console.error("Erro ao confirmar empréstimo:", error);
      this.$notify({
        title: "Erro",
        text: "Ocorreu um erro ao confirmar o empréstimo. Por favor, tente novamente.",
        type: "error"
      });
    } finally {
      this.loadingResume = false;
    }
  }

  get returnValue(): number {
    return (this.resume?.requestedAmount as number) - this.sumOfValues;
  }

  get sumOfValues(): number {
    const labelMappings = {
      [this.loanTypesIds.COMPRA_DE_DIVIDA]: this.sumOfDebts,
      [this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO]:
        this.sumOfInstallmentsRefinancings
    };

    return labelMappings[this.loanTypeId] || 0;
  }

  get sumOfDebts(): number {
    return sumBy(this.resume?.debts, (d) => Number(d.value));
  }

  get sumOfInstallmentsRefinancings(): number {
    return sumBy(this.resume?.refinancing?.installmentsRefinancings, (r) =>
      Number(r.presentValue)
    );
  }

  get loanTypeId(): number {
    return this.resume?.typeId;
  }

  formatDate(date: string): string {
    if (!date) {
      date = new Date().toISOString();
    }
    return dayjs(date).format("DD/MM/YYYY");
  }

  dateDiffInDays(date: string): number {
    return dayjs(date).diff(new Date(), "days");
  }

  get isDebtPurchase(): boolean {
    return this.loanTypeId === this.loanTypesIds.COMPRA_DE_DIVIDA;
  }

  get isRefinancingOfInProgressLoans(): boolean {
    return (
      this.loanTypeId === this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO
    );
  }

  get isPixPayment(): boolean {
    return this.resume.loanPaymentTypeId === LoanPaymentTypeEnum.PIX.id;
  }
}
