
import AlertBox from "@/components/AlertBox.vue";
import FieldErrors from "@/components/FieldErrors.vue";
import Modal from "@/components/Modal.vue";
import SaveButton from "@/components/SaveButton.vue";
import DebtPurchaseManage from "@/components/loans/DebtPurchaseManage.vue";
import ProposalConditions from "@/components/loans/ProposalConditions.vue";
import RefinancingLoansManage from "@/components/loans/RefinancingLoansManage.vue";
import SliderInput from "@/components/loans/SliderInput.vue";
import TermsDialog from "@/components/loans/TermsDialog.vue";
import dayjs from "@/plugins/day-js";
import CreditPolicyService from "@/services/credit-policy-service";
import FinancialCompanyService, {
  SimulateProposalResponse
} from "@/services/financial-company-service";
import LoanService, { SaveLoanResponse } from "@/services/loan-service";
import SafeBoardingService, {
  CreditPolicyRange
} from "@/services/safe-boarding-service";
import Company from "@/types/company";
import { loanTypesIds, loanTypesNames } from "@/types/loan";
import LoanDebt from "@/types/loan-debt";
import { checkIfLoanFlowCacheIsRecent } from "@/utils/checkIfLoanFlowCacheIsRecent";
import formatCurrency from "@/utils/formatCurrency";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import debounce from "debounce";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import Refinancing from "../../types/refinancing";
import { AllowedToRefinancingLoan } from "../../services/loan-service/types/allowed-to-refinancing-loan";
import MarginBaseService, {
  MarginBaseRegistersGroupedByCompany
} from "@/services/margin-base-service";
import { format as formatCNPJ } from "@/utils/cnpj";
import ConsignetDataSelection from "@/components/margin-bases/ConsignetDataSelection.vue";
import Products from "@/types/products";
import { BankingEnum, getBankingEnumNameById } from "@/types/BankingEnum";
import { getAdministratorEnumNameById } from "@/types/AdministratorEnum";
import CompanyService from "../../services/company-service";

interface LabelMappings {
  [loanType: number]: {
    requestedAmountLabel: string;
    installmentValueLabel: string;
    firstInstallmentDueDateLabel: string;
    sumOfValuesLabel?: string;
    minSumOfValues: number;
    returnValueLabel?: string;
    requestedAmountMinValue: number;
    requestedAmountMaxValue: number;
  };
}

interface State {
  loanType: string;
  form: any;
  marginBaseRegisters: MarginBaseRegistersGroupedByCompany[];
  shouldShowConsignetDataSelection: boolean;
  consignetRelatedCompanies: Company[];
  productsByCompany: { [companyId: number]: Array<Partial<Products>> };
  proposal: any;
  company: any;
  resetCreditEngine: boolean;
  preventCreditEngineFromRunning: boolean;
  runningCreditEngine: boolean;
  completedCreditEngine: boolean;
  acceptTerms: boolean;
}

interface Cache extends State {
  _cacheDate: Date;
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    SaveButton,
    SliderInput,
    FieldErrors,
    TermsDialog,
    ProposalConditions,
    AlertBox,
    RefinancingLoansManage,
    DebtPurchaseManage,
    ConsignetDataSelection
  },
  directives: { mask }
})
export default class LoanCalculate extends Vue {
  @Prop() readonly loan!: SaveLoanResponse;
  @Prop({ type: Object, default: () => ({}) }) cache!: Cache;
  /** Used to preserve state (i.e., all relevant data) during re-renders caused by reactive behavior */
  @Prop() readonly editState!: State;
  /** Used on component creation to avoid treating programmatic changes to form as if they were actual user input */
  isProgrammaticFormInput: boolean = false;
  creditPolicyService: CreditPolicyService;
  safeBoardingService: SafeBoardingService;
  financialCompanyService: FinancialCompanyService;
  loanService: LoanService;
  marginBaseService: MarginBaseService;
  companyService: CompanyService;
  loadingMarginBaseRegisters = false;
  loading = false;
  showConditionsModal = false;
  acceptTerms = false;
  showTermsError = false;
  formatCurrency = formatCurrency;
  formatCNPJ = formatCNPJ;

  marginBaseRegisters: MarginBaseRegistersGroupedByCompany[] = [];
  shouldShowConsignetDataSelection: boolean = false;
  consignetRelatedCompanies: Company[] = [];
  productsByCompany: { [companyId: number]: Array<Partial<Products>> } = {};

  debts = [] as LoanDebt[];
  sumOfDebts = 0;

  refinancing = { installmentsRefinancings: [] } as Refinancing;
  sumOfInstallmentsRefinancings = 0;
  allowedToRefinancingLoans: Array<AllowedToRefinancingLoan> = [];

  proposal = {
    insuranceValue: 0,
    tacValue: 0,
    iofValue: 0,
    iofPercentual: 0,
    billetValue: 0,
    requestedValue: 0,
    creditAmount: 0,
    debitAmount: 0,
    installmentsTotal: 0,
    installmentsValue: 0,
    monthlyFeePercentual: 0,
    annualFeePercentual: 0,
    monthlyCETPercentual: 0,
    annualCETPercentual: 0,
    installments: []
  } as SimulateProposalResponse;
  form = {
    marginBaseRegister: {
      id: this.editState?.form?.marginBaseRegister?.id || 0
    } as MarginBaseRegistersGroupedByCompany,
    previousAmount: 1000, // used in order to compare new amount from calculator with previous one
    amount: 1000,
    returnValue: 0,
    sumOfValues: 0,
    minSumOfValues: 10,
    minAmount: 500,
    maxAmount: 1000,
    numInstallments: this.loan.loan.numInstallments || 6,
    minInstallments: 6,
    maxInstallments: 6,
    installmentValue: 0,
    interestRate: 2,
    withInsurance: this.loan.loan.withInsurance,
    creditPolicyRanges: [] as CreditPolicyRange[] | undefined,
    previousCreditPolicyRange: {} as CreditPolicyRange | undefined,
    previousWithInsurance: true,
    firstInstallmentDate: "",
    requestObservation: "" as string | undefined,
    bankingId: 1,
    bankingName: "",
    administratorId: 1,
    administratorName: ""
  };
  resetCreditEngine = true;
  preventCreditEngineFromRunning = false;
  runningCreditEngine = false;
  completedCreditEngine = false;
  company = undefined as
    | MarginBaseRegistersGroupedByCompany["company"]
    | undefined;
  loanTypesIds = loanTypesIds;
  loanTypesNames = loanTypesNames;
  minimumDueDate = dayjs().add(5, "days").toDate();
  debouncedCalculate = debounce(this.calculate, 1500);
  debouncedHandleReturnValueChanged = debounce(
    this.handleReturnValueChanged,
    1500
  );
  debounceHandleSumOfValuesChanged = debounce(
    this.handleSumOfValuesChanged,
    1500
  );

  constructor() {
    super();

    this.creditPolicyService = CreditPolicyService.getInstance();
    this.safeBoardingService = SafeBoardingService.getInstance();
    this.financialCompanyService = FinancialCompanyService.getInstance();
    this.loanService = LoanService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    this.companyService = CompanyService.getInstance();
  }

  get dataThatTriggersCreditEngineReset() {
    return {
      loan: this.loan,
      marginBaseRegister: this.form.marginBaseRegister
    };
  }

  @Watch("dataThatTriggersCreditEngineReset", { deep: true })
  async creditEngineReset(): Promise<void> {
    if (this.loanTypeId === this.loanTypesIds.ANTECIPACAO_DE_SALARIO) {
      this.form.withInsurance = false;
      this.form.numInstallments = 1;
      this.form.maxInstallments = 1;
      this.form.minInstallments = 1;
    } else {
      this.form.withInsurance = true;
    }
    this.form.requestObservation = this.loan.loan?.requestObservation;

    if (!this.preventCreditEngineFromRunning) {
      this.resetCreditEngine = true;
      this.onUnsavedChanges({ forceEmit: true }); // since we run credit engine and simulate from scratch here, we need to treat it as unsaved changes, so that we don't mislead users that jump from this step to the confirmation step
      await this.runCreditEngine({});
      await this.calculate({});
    } else {
      this.preventCreditEngineFromRunning = false;
    }
  }

  @Watch("form", { deep: true })
  async formChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("proposal", { deep: true })
  async proposalChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("company", { deep: true })
  async companyChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("resetCreditEngine", { deep: true })
  async resetCreditEngineChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("runningCreditEngine", { deep: true })
  async runningCreditEngineChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("completedCreditEngine", { deep: true })
  async completedCreditEngineChanged(): Promise<void> {
    this.onUnsavedChanges();
  }
  @Watch("acceptTerms", { deep: true })
  async acceptTermsChanged(): Promise<void> {
    this.onUnsavedChanges();
  }

  async mounted(): Promise<void> {
    if (this.isRefinancingOfInProgressLoans) {
      await this.fetchRefinancingLoans();
    }

    this.isProgrammaticFormInput = true;

    if (!isEmpty(this.editState)) {
      this.restoreDataFrom(this.editState);
    } else {
      if (this.isCacheValid()) {
        this.restoreDataFrom(this.cache);
      } else {
        await this.loadMarginBaseRegisters();
        await this.loadCacheableData();
      }

      this.overrideManuallyInputedValues();
    }

    this.$nextTick(() => {
      this.isProgrammaticFormInput = false;
    });
  }

  async fetchRefinancingLoans(): Promise<void> {
    this.startLoading();

    const [error, response] =
      await this.loanService.listAllowedToRefinancingLoans(
        this.loan.borrower.cpf
      );

    if (error || !response) {
      return this.$notify({
        title: "Erro",
        text: "Não foi possível carregar os empréstimos disponíveis para refinanciamento.",
        type: "error"
      });
    }

    this.allowedToRefinancingLoans = response;
    this.stopLoading();
  }

  async loadCacheableData() {
    const { loan } = this.loan;

    if (loan.requestObservation)
      this.form.requestObservation = loan.requestObservation;
  }

  isCacheValid(): boolean {
    const isCacheRecent = checkIfLoanFlowCacheIsRecent(this.cache?._cacheDate);
    const loanTypeHasNotChanged =
      this.cache.loanType === this.loan?.loan.type.name;

    return (
      isCacheRecent &&
      loanTypeHasNotChanged &&
      !isEmpty(this.cache) &&
      !isEmpty(this.cache.loanType) &&
      !isEmpty(this.cache.form) &&
      !isEmpty(this.cache.proposal) &&
      !isEmpty(this.cache.company) &&
      !isEmpty(this.cache.productsByCompany)
    );
  }

  get isValidSumOfValues(): boolean {
    if (this.isRefinancingOfInProgressLoans || this.isDebtPurchase) {
      return (
        this.sumOfValuesRespectAmount &&
        this.sumOfValuesRespectMaximumAmount &&
        this.sumOfValuesRespectMinimumAmount &&
        this.sumOfValuesIsEqualInputedValue
      );
    }

    return true;
  }

  saveCache(): void {
    const data: Cache = {
      _cacheDate: new Date(),
      loanType: this.loan?.loan.type.name,
      form: this.form,
      marginBaseRegisters: this.marginBaseRegisters,
      shouldShowConsignetDataSelection: this.shouldShowConsignetDataSelection,
      consignetRelatedCompanies: this.consignetRelatedCompanies,
      productsByCompany: this.productsByCompany,
      proposal: this.proposal,
      company: this.company,
      resetCreditEngine: this.resetCreditEngine,
      preventCreditEngineFromRunning: this.preventCreditEngineFromRunning,
      runningCreditEngine: this.runningCreditEngine,
      completedCreditEngine: this.completedCreditEngine,
      acceptTerms: this.acceptTerms
    };
    this.$emit("loadData", cloneDeep(data));
  }

  restoreDataFrom(data: State | Cache): void {
    this.form = cloneDeep(data.form);
    this.marginBaseRegisters = cloneDeep(data.marginBaseRegisters);
    this.shouldShowConsignetDataSelection = cloneDeep(
      data.shouldShowConsignetDataSelection
    );
    this.consignetRelatedCompanies = cloneDeep(data.consignetRelatedCompanies);
    this.productsByCompany = cloneDeep(data.productsByCompany);
    this.proposal = cloneDeep(data.proposal);
    this.company = cloneDeep(data.company);
    this.resetCreditEngine = cloneDeep(data.resetCreditEngine);
    this.preventCreditEngineFromRunning = cloneDeep(
      data.preventCreditEngineFromRunning
    );
    this.runningCreditEngine = cloneDeep(data.runningCreditEngine);
    this.completedCreditEngine = cloneDeep(data.completedCreditEngine);
    this.acceptTerms = cloneDeep(data.acceptTerms);
  }

  get sumOfValuesRespectAmount(): boolean {
    return this.sumOfValues <= this.form.amount;
  }
  get sumOfValuesRespectMinimumAmount(): boolean {
    return (
      this.sumOfValues >= this.labelMappings[this.loanTypeId]?.minSumOfValues
    );
  }
  get sumOfValuesRespectMaximumAmount(): boolean {
    return this.sumOfValues <= this.form.maxAmount - this.form.minAmount;
  }

  get sumOfValuesIsEqualInputedValue(): boolean {
    return (
      Number(this.form.sumOfValues.toFixed(2)) ===
      Number(this.sumOfValues.toFixed(2))
    );
  }

  get marginBaseRegistersList(): { value: number; text: string }[] {
    return this.marginBaseRegisters.map((marginBaseRegister) => ({
      value: marginBaseRegister.id,
      text:
        marginBaseRegister.company.name +
        " - " +
        formatCNPJ(marginBaseRegister.company.cnpj)
    }));
  }

  get selectedMarginBaseRegister(): {
    admissionDate: string;
    companyTime: string;
    liquidAmount: string;
    occupation: string;
  } | null {
    if (this.marginBaseRegistersList.length) {
      const selected = this.marginBaseRegisters.find(
        (marginBaseRegister: MarginBaseRegistersGroupedByCompany) => {
          return marginBaseRegister.id === this.form.marginBaseRegister.id;
        }
      );

      if (selected) {
        const [matchingProduct] = this.getProductsForCompanyId(
          selected.company.id
        ).filter(
          (product) =>
            product.typeId === this.loan.loan.typeId && product.isActive
        );

        if (matchingProduct) {
          this.form.bankingId = matchingProduct.bankingId;
          this.form.bankingName = getBankingEnumNameById(
            matchingProduct.bankingId
          );
          this.form.administratorId = matchingProduct.adminId;
          this.form.administratorName = getAdministratorEnumNameById(
            matchingProduct.adminId
          );

          return {
            admissionDate: dayjs(selected.admissionDate).format("DD/MM/YYYY"),
            companyTime: dayjs().diff(selected.admissionDate, "year") + " anos",
            liquidAmount: formatCurrency(Number(selected.liquidIncome)),
            occupation: selected.occupation
          };
        }
      }
    }
    return null;
  }

  async onSaveConsignetDataSelection() {
    this.shouldShowConsignetDataSelection = false;
    await this.loadMarginBaseRegisters();
  }

  onUnsavedChanges(options?: { forceEmit?: boolean }): void {
    if (!this.isProgrammaticFormInput || options?.forceEmit) {
      this.$emit("unsavedChanges");
    }

    /**
     * Always set `preventCreditEngineFromRunning` to `true` when there are unsaved changes,
     * so that when the user stays in this step (after trying to navigate to another step),
     * the credit engine is not run automatically. This is necessary because the credit engine
     * was being triggered by the `dataThatTriggersCreditEngineReset` watcher.
     */
    const preventCreditEngineFromRunning = true;

    const data: State = {
      loanType: this.loan?.loan.type.name,
      form: this.form,
      marginBaseRegisters: this.marginBaseRegisters,
      shouldShowConsignetDataSelection: this.shouldShowConsignetDataSelection,
      consignetRelatedCompanies: this.consignetRelatedCompanies,
      productsByCompany: this.productsByCompany,
      proposal: this.proposal,
      company: this.company,
      resetCreditEngine: this.resetCreditEngine,
      preventCreditEngineFromRunning,
      runningCreditEngine: this.runningCreditEngine,
      completedCreditEngine: this.completedCreditEngine,
      acceptTerms: this.acceptTerms
    };
    this.$emit("updateEditState", cloneDeep(data));
  }

  async loadMarginBaseRegisters(): Promise<void> {
    this.loadingMarginBaseRegisters = true;
    this.marginBaseRegisters = [];
    const [marginBaseRegisterError, marginBaseRegistersData] =
      await this.marginBaseService.listMarginBaseRegistersGroupedByCompany(
        this.loan.borrower.cpf.replace(/\D/g, "")
      );

    if (marginBaseRegisterError) {
      const thereIsNoMarginBaseRegisterForThisBorrower =
        marginBaseRegisterError.response?.status === 404;
      if (thereIsNoMarginBaseRegisterForThisBorrower) {
        const [
          borrowerCompanyRelatedConsignetPartnershipsError,
          borrowerCompanyRelatedConsignetPartnerships
        ] =
          await this.marginBaseService.findCompanyRelatedConsignetPartnerships(
            this.loan.borrower.cpf.replace(/\D/g, "")
          );

        if (!borrowerCompanyRelatedConsignetPartnerships?.length) {
          this.loadingMarginBaseRegisters = false;
          return;
        } else {
          this.consignetRelatedCompanies =
            borrowerCompanyRelatedConsignetPartnerships;
          this.shouldShowConsignetDataSelection = true;
        }
      } else {
        this.loadingMarginBaseRegisters = false;
        return this.$notify({
          title: "Erro",
          text: "Não foi possível carregar os vínculos empregatícios e margem base para este CPF.",
          type: "error"
        });
      }
    }

    if (marginBaseRegistersData) {
      const [productError, productData] =
        await this.companyService.findProductsByCompanies(
          marginBaseRegistersData.map((mbr) => mbr.company.id)
        );

      if (productError) {
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(productError)
        });
      }

      if (productData) {
        this.productsByCompany = productData;

        // Filter for margin base registers whose company product for the loan's type is active
        this.marginBaseRegisters = marginBaseRegistersData.filter((mbr) =>
          this.getProductsForCompanyId(mbr.company.id).find(
            (product) =>
              product.typeId === this.loan.loan.typeId && product.isActive
          )
        );

        // Handle margin base register selection
        if (this.loan.marginBaseRegister?.id) {
          const existingMarginBaseRegisterSelection =
            this.marginBaseRegisters.find(
              (mbr) => mbr.id === this.loan.marginBaseRegister.id
            );

          if (existingMarginBaseRegisterSelection)
            this.form.marginBaseRegister.id =
              existingMarginBaseRegisterSelection.id;
        } else {
          // Always select a company
          this.form.marginBaseRegister.id = this.marginBaseRegisters[0].id;
          await this.updateLoanMarginBaseRegister();
        }
      }
    }
    this.loadingMarginBaseRegisters = false;
  }

  getProductsForCompanyId(companyId: number): Array<Partial<Products>> {
    return this.productsByCompany[companyId] || [];
  }

  async runCreditEngine({
    amount,
    manageLoading = true,
    shouldUpdateMaxAmount
  }: {
    amount?: number;
    manageLoading?: boolean;
    shouldUpdateMaxAmount?: boolean;
  }): Promise<boolean> {
    if (this.selectedMarginBaseRegister) {
      const marginBaseRegister = this.form.marginBaseRegister;
      if (!marginBaseRegister.id) return false;

      if (manageLoading) this.startLoading();

      this.runningCreditEngine = true;

      this.company = marginBaseRegister.company;

      const [creditEngineResultError, creditEngineResult] =
        await this.safeBoardingService.runCreditEngine({
          loanType: this.loanTypesNames[this.loan.loan.typeId],
          withInsurance: this.form.withInsurance,
          amount,
          marginBaseRegisterId: marginBaseRegister.id,
          unconsideredMarginInstallmentIds:
            this.unconsideredMarginInstallmentIds,
          numInstallments: this.resetCreditEngine
            ? undefined
            : this.form.numInstallments
        });

      if (creditEngineResultError) {
        if (manageLoading) this.stopLoading();
        this.runningCreditEngine = false;
        this.$notify({
          title: "Erro",
          text: getErrorMessageFromApiError(creditEngineResultError),
          type: "error"
        });
        this.completedCreditEngine = false;
        if (
          [
            "InvalidProposalException",
            "InvalidAnticipationProposalException"
          ].includes(creditEngineResultError.response?.data.exception)
        ) {
          this.goToUnavailableMarginPage();
        }
        return false;
      }

      if (creditEngineResult) {
        this.form.minAmount = creditEngineResult.minAmount;
        this.form.maxAmount = creditEngineResult.maxAmount;
        this.form.creditPolicyRanges = creditEngineResult?.creditPolicyRanges;

        if (this.resetCreditEngine || shouldUpdateMaxAmount) {
          this.form.amount = creditEngineResult.maxAmount;
        }
        this.form.previousAmount = this.form.amount;
        this.form.previousWithInsurance = this.form.withInsurance;
        this.form.previousCreditPolicyRange = this.getCurrentRange();

        this.form.minInstallments = creditEngineResult.minInstallments;
        this.form.maxInstallments = creditEngineResult.maxInstallments;
        this.form.interestRate = creditEngineResult.fee;

        this.handleOutOfRangeNumberOfInstallments();

        this.completedCreditEngine = true;
        this.runningCreditEngine = false;
        this.resetCreditEngine = false;
      }

      if (manageLoading) this.stopLoading();
    }
    return true;
  }

  /** Depends on this.runCreditEngine() being run before. */
  async calculate({
    shouldUpdateMaxAmount
  }: {
    shouldUpdateMaxAmount?: boolean;
  }): Promise<void> {
    if (this.selectedMarginBaseRegister) {
      const marginBaseRegister = this.form.marginBaseRegister;
      if (!marginBaseRegister.id) return;

      // const currentRange = this.getCurrentRange();

      // * Avoid running credit engine when theres no range from credit engine
      // if (this.form.creditPolicyRanges?.length && !currentRange) return;

      this.startLoading();

      //* Run credit engine if amount, insurance, selected refinancings or credit policy range changed.
      if (
        this.form.amount !== this.form.previousAmount ||
        this.form.withInsurance !== this.form.previousWithInsurance ||
        // (this.form.previousCreditPolicyRange &&
        //   currentRange !== this.form.previousCreditPolicyRange) ||
        shouldUpdateMaxAmount
      ) {
        this.form.previousAmount = this.form.amount;

        const creditEngineSuccess = await this.runCreditEngine({
          amount: shouldUpdateMaxAmount ? undefined : this.form.amount,
          manageLoading: false,
          shouldUpdateMaxAmount
        });
        if (!creditEngineSuccess) return;
      }

      this.handleOutOfAllowedValues();
      this.handleOutOfRangeNumberOfInstallments();

      const [proposalError, proposal] =
        await this.financialCompanyService.internalSimulateProposal({
          loanType: this.loanTypesNames[this.loanTypeId],
          amount: this.form.amount,
          installments: this.form.numInstallments,
          negotiatedFee: this.form.interestRate,
          withInsurance: this.form.withInsurance,
          marginBaseRegisterId: marginBaseRegister.id
        });

      if (proposalError) {
        this.stopLoading();
        this.$notify({
          title: "Erro",
          text: getErrorMessageFromApiError(proposalError),
          type: "error"
        });
        return;
      }

      if (proposal) {
        this.proposal = proposal;
        this.form.firstInstallmentDate = this.formatDate(
          proposal.installments[0].dueDate
        );
      }
    }

    this.stopLoading();
  }

  async updateLoanMarginBaseRegister(): Promise<void> {
    this.startLoading();

    const [loanError, updatedLoan] =
      await this.loanService.saveLoanMarginBaseRegister({
        cpf: this.loan.borrower.cpf,
        data: {
          marginBaseRegister: {
            id: this.form.marginBaseRegister?.id
          }
        }
      });

    if (loanError) {
      this.stopLoading();
      this.$notify({
        title: "Erro",
        text: "Erro ao selecionar base de margem.",
        type: "error"
      });
      return;
    }

    if (updatedLoan) {
      this.$notify({
        title: "Sucesso",
        text: "Base de margem selecionada com sucesso.",
        type: "success"
      });

      this.saveCache();
      this.$emit("updatedLoan", updatedLoan);
      if (this.isRefinancingOfInProgressLoans) {
        await this.fetchRefinancingLoans();
      }
    }

    this.stopLoading();
  }

  async save(): Promise<void> {
    if (this.acceptTerms) {
      this.startLoading();

      const [loanError, updatedLoan] = await this.loanService.saveLoanCalculate(
        {
          cpf: this.loan.borrower.cpf,
          data: {
            marginBaseRegister: {
              id: this.form.marginBaseRegister?.id
            },
            loan: {
              requestedAmount: this.proposal.requestedValue.toString(),
              total: this.proposal.debitAmount.toString(),
              numInstallments: this.form.numInstallments.toString(),
              installmentValue: this.proposal.installmentsValue.toString(),
              monthlyFee: this.proposal.monthlyFeePercentual.toString(),
              annualFee: this.proposal.annualFeePercentual.toString(),
              monthlyCET: this.proposal.monthlyCETPercentual.toString(),
              annualCET: this.proposal.annualCETPercentual.toString(),
              iofValue: this.proposal.iofValue.toString(),
              iofPercentage: this.proposal.iofPercentual.toString(),
              tacValue: this.proposal.tacValue.toString(),
              creditAmount: this.proposal.creditAmount.toString(),
              insuranceValue: this.proposal.insuranceValue.toString(),
              withInsurance: this.form.withInsurance,
              bankingId: this.form.bankingId,
              bankingName: this.form.bankingName,
              administratorId: this.form.administratorId,
              administratorName: this.form.administratorName,
              requestObservation: this.form.requestObservation,
              ...(this.isDebtPurchase ? { debts: this.debts } : {}),
              ...(this.isRefinancingOfInProgressLoans
                ? { refinancing: this.refinancing }
                : {})
            }
          }
        }
      );

      if (loanError) {
        this.stopLoading();
        this.$notify({
          title: "Erro",
          text: getErrorMessageFromApiError(loanError),
          type: "error"
        });
        return;
      }

      if (updatedLoan) {
        this.$notify({
          title: "Sucesso",
          text: "Cálculo de empréstimo salvo com sucesso.",
          type: "success"
        });

        this.saveCache();
        this.$emit("updatedLoan", updatedLoan);
        this.goToNextStep();
      }

      this.stopLoading();
    } else {
      this.showTermsError = true;
    }
  }

  handleOutOfRangeNumberOfInstallments(): void {
    if (
      this.form.numInstallments < this.form.minInstallments ||
      this.form.numInstallments > this.form.maxInstallments ||
      this.resetCreditEngine
    ) {
      this.form.numInstallments = this.form.maxInstallments;
    }
  }

  handleOutOfAllowedValues(): void {
    if (this.isDebtPurchase || this.isRefinancingOfInProgressLoans) {
      this.form.returnValue = this.clampValue({
        desiredValue: this.form.amount - this.form.sumOfValues,
        minValue: this.form.minAmount,
        maxValue:
          this.form.maxAmount -
          this.labelMappings[this.loanTypeId]?.minSumOfValues
      });

      this.handleReturnValueChanged();
    }
  }

  handleReturnValueChanged(): void {
    if (this.isDebtPurchase || this.isRefinancingOfInProgressLoans) {
      /**
       * If return value change, update sum of values, always respecting
       * the minimum and maximum amount
       */
      this.form.sumOfValues = this.clampValue({
        desiredValue: this.form.amount - this.form.returnValue,
        minValue: this.labelMappings[this.loanTypeId]?.minSumOfValues,
        maxValue:
          this.form.maxAmount -
          this.labelMappings[this.loanTypeId]?.minSumOfValues
      });
      this.updateAmountIfNeeded();
    }
  }

  handleSumOfValuesChanged(): void {
    if (this.isDebtPurchase || this.isRefinancingOfInProgressLoans) {
      /**
       * If sum of values change, update return value, always respecting
       * the minimum and maximum amount
       */
      this.form.returnValue = this.clampValue({
        desiredValue: this.form.amount - this.form.sumOfValues,
        minValue: this.form.minAmount,
        maxValue:
          this.form.maxAmount -
          this.labelMappings[this.loanTypeId]?.minSumOfValues
      });

      this.updateAmountIfNeeded();
    }
  }

  updateAmountIfNeeded(): void {
    /**
     * Update the amount if necessary, to ensure that the sum of values
     * and return values does not exceed it
     */
    const previousAmount = this.form.amount;

    this.form.amount = this.clampValue({
      desiredValue: this.form.returnValue + this.form.sumOfValues,
      minValue: this.form.minAmount,
      maxValue: this.form.maxAmount
    });

    const shouldExecuteCrediteEngine = previousAmount !== this.form.amount;
    if (shouldExecuteCrediteEngine) this.calculate({});
  }

  sumOfDebtsChanged(sum: number): void {
    this.sumOfDebts = sum;
    this.overrideManuallyInputedValues();
  }

  sumOfInstallmentsRefinancingsChanged(sum: number): void {
    this.sumOfInstallmentsRefinancings = sum;
    this.overrideManuallyInputedValues();
  }

  overrideManuallyInputedValues(): void {
    this.form.sumOfValues =
      this.sumOfValues || this.labelMappings[this.loanTypeId]?.minSumOfValues;
    this.handleSumOfValuesChanged();
  }

  getCurrentRange(): CreditPolicyRange | undefined {
    const { numInstallments } = this.form;

    return this.findCreditPolicyRange(numInstallments);
  }

  findCreditPolicyRange(
    numInstallments: number
  ): CreditPolicyRange | undefined {
    const { creditPolicyRanges } = this.form;

    if (creditPolicyRanges?.length) {
      return creditPolicyRanges.find(
        (range) =>
          numInstallments >= range.installmentMinimumRange &&
          numInstallments <= range.installmentMaximumRange
      );
    }
  }

  get isValidInstallmentsRange(): boolean {
    const currentRange = this.getCurrentRange();

    if (currentRange) return true;

    return false;
  }

  get unconsideredMarginInstallmentIds(): number[] {
    if (!this.isRefinancingOfInProgressLoans) return [];

    if (this.resetCreditEngine) {
      return this.loan.loan?.refinancing?.installmentsRefinancings.map(
        (ir) => ir.loanInstallmentId
      );
    }

    return this.refinancing?.installmentsRefinancings?.map(
      (ir) => ir.loanInstallmentId
    );
  }

  get loanTypeId(): number {
    return this.loan?.loan?.typeId;
  }

  get isSalaryAnticipation(): boolean {
    return this.loanTypeId === this.loanTypesIds.ANTECIPACAO_DE_SALARIO;
  }

  get isDebtPurchase(): boolean {
    return this.loanTypeId === this.loanTypesIds.COMPRA_DE_DIVIDA;
  }

  get isRefinancingOfInProgressLoans(): boolean {
    return (
      this.loanTypeId === this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO
    );
  }

  get returnValue(): number {
    return Math.max(0, this.form.amount - this.sumOfValues);
  }

  get sumOfValues(): number {
    const labelMappings = {
      [this.loanTypesIds.COMPRA_DE_DIVIDA]: this.sumOfDebts,
      [this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO]:
        this.sumOfInstallmentsRefinancings
    };

    return labelMappings[this.loanTypeId] || 0;
  }

  get labelMappings(): LabelMappings {
    return {
      [this.loanTypesIds.ANTECIPACAO_DE_SALARIO]: {
        requestedAmountLabel: "Quero antecipar",
        installmentValueLabel: "Valor à pagar",
        firstInstallmentDueDateLabel: "Vencimento da antecipação",
        requestedAmountMinValue: this.form.minAmount,
        requestedAmountMaxValue: this.form.maxAmount,
        minSumOfValues: this.form.minSumOfValues
      },
      [this.loanTypesIds.EMPRESTIMO_CONSIGNADO]: {
        requestedAmountLabel: "Quero contratar",
        installmentValueLabel: "Valor da parcela",
        firstInstallmentDueDateLabel: "Primeira parcela",
        requestedAmountMinValue: this.form.minAmount,
        requestedAmountMaxValue: this.form.maxAmount,
        minSumOfValues: this.form.minSumOfValues
      },
      [this.loanTypesIds.COMPRA_DE_DIVIDA]: {
        requestedAmountLabel: "Quero contratar",
        installmentValueLabel: "Valor da parcela",
        firstInstallmentDueDateLabel: "Primeira parcela",
        sumOfValuesLabel: "Valor da compra",
        returnValueLabel: "Valor troco",
        requestedAmountMinValue: this.form.minAmount + this.form.minSumOfValues,
        requestedAmountMaxValue: this.form.maxAmount,
        minSumOfValues: this.form.minSumOfValues
      },
      [this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO]: {
        requestedAmountLabel: "Quero contratar",
        installmentValueLabel: "Valor da parcela",
        firstInstallmentDueDateLabel: "Primeira parcela",
        sumOfValuesLabel: "Valor refinanciado",
        returnValueLabel: "Valor troco",
        requestedAmountMinValue: this.form.minAmount + this.form.minSumOfValues,
        requestedAmountMaxValue: this.form.maxAmount,
        minSumOfValues: this.form.minSumOfValues
      }
    } as LabelMappings;
  }

  goToUnavailableMarginPage(): void {
    this.$router.push("/contratar-emprestimo/sem-margem-disponivel");
  }

  updateInsurance(withInsurance: boolean): void {
    this.form.withInsurance = withInsurance;
    this.calculate({});
  }

  get shouldShowInsurance(): boolean {
    return [
      this.loanTypesIds.EMPRESTIMO_CONSIGNADO,
      this.loanTypesIds.COMPRA_DE_DIVIDA,
      this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO
    ].includes(this.loanTypeId);
  }

  get shouldShowTAC(): boolean {
    return [
      this.loanTypesIds.EMPRESTIMO_CONSIGNADO,
      this.loanTypesIds.COMPRA_DE_DIVIDA,
      this.loanTypesIds.REFINANCIAMENTO_CONTRATOS_ANDAMENTO
    ].includes(this.loanTypeId);
  }

  clampValue({
    desiredValue,
    minValue,
    maxValue
  }: {
    desiredValue: number;
    minValue: number;
    maxValue: number;
  }): number {
    return Math.min(Math.max(desiredValue, minValue), maxValue);
  }

  showConditions(): void {
    this.showConditionsModal = true;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  goToPreviousStep(): void {
    this.$emit("previous");
  }

  goToNextStep(): void {
    this.$emit("next");
  }
}
